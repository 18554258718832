<template>
  <div class="calculate-status-list" :class="{skeleton: !state.calculate.loaded}">
    <div class="top">
      <span>총 : {{ state.calculate.total }} 건</span>

      <div class="right">
        <select class="form-control" v-model="state.condition" @change="setCondition('status', $event.target.value)">
          <option value="planned">정산 요청 예정</option>
          <option value="CAL_STS_0001">정산 요청 중</option>
          <option value="CAL_STS_0002">미정산 프로젝트</option>
          <option value="CAL_STS_0003">정산 마감 프로젝트</option>
          <option value="CAL_STS_0004">정산 확인 요청</option>
          <option value="CAL_STS_0005">정산 마감 요청</option>
        </select>
        <label class="search" :for="`${component.name}Search`">
          <input type="text" class="form-control form-sm" v-model="state.keyword" @keyup.enter="search()" placeholder="프로젝트 번호를 입력해주세요">
          <span class="reset pointer" @click="search(true)" v-if="$route.query.keyword">&times;</span>
          <button class="btn" @click="search()">
            <i class="fa fa-search"></i>
          </button>
        </label>
      </div>
    </div>
    <template v-if="!state.calculate.loaded || state.calculate.list.length">
      <ul class="accordion tight">
        <li class="card">
          <div class="row">
            <div class="col-4">
              <b>프로젝트 제목</b>
            </div>
            <div class="col-2">
              <b>송금 금액</b>
            </div>
            <div class="col-2">
              <b>수수료(지원금액 차감)</b>
            </div>
            <div class="col-2">
              <b>프로젝트 마감일</b>
            </div>
            <div class="col-2">
              <b>정산서 발송 예정일</b>
            </div>
          </div>
        </li>
        <li class="card" v-for="(c, idx) in state.calculate.list" :key="idx">
          <div class="front" :id="`${component.name}CalculateProject${c.projectSeq}`">
            <div class="row" data-toggle="collapse" :data-target="`#${component.name}CalculateProjectDetail${c.projectSeq}`" :aria-controls="`${component.name}CalculateProjectDetail${c.projectSeq}`">
              <div class="col-4 ellipsis title">
                <template v-if="state.condition === 'planned'">
                  <span v-if="c.expenseAmt > 0" class="badge badge-purple mr-1">정산 필요</span>
                  <span v-else class="badge badge-secondary mr-1">정산 불요</span>
                </template>
                <Anchor :href="`/manage/reward/${c.projectSeq}/calculateNew`" target="_blank" rel="noopener noreferrer" :title="c.projectName" class="ellipsis">
                  <b class="color-anchor">{{ c.projectName }}</b>
                </Anchor>
              </div>
              <div class="col-2">
                <span>{{ $lib.getNumberFormat(c.accountAmt) || "(N/A)" }}</span>
              </div>
              <div class="col-2">
                <span>{{ $lib.getNumberFormat(c.subtotalCharge - c.supportCharge) }}</span>
              </div>
              <div class="col-2">
                <span>{{ $lib.getDateFormat(c.investEndDate, "yyyy-MM-dd") }}</span>
              </div>
              <div class="col-2">
                <span>{{ getShipDate(c.investEndDate, c.fundingType === "A" ? 6 : 4) }}</span>
              </div>
            </div>
          </div>
          <div :id="`${component.name}CalculateProjectDetail${c.projectSeq}`" class="collapse detail" :aria-labelledby="`${component.name}CalculateProject${c.projectSeq}`">
            <div class="row">
              <div class="col-10">
                <span class="item">프로젝트 번호: {{ c.projectSeq }}</span>
                <span class="item">모집 금액: {{ $lib.getNumberFormat(c.expenseAmt) || 0 }}원</span>
              </div>
              <div class="col-2" v-if="['CAL_STS_0004', 'CAL_STS_0005'].includes(c.calculateStatusCode)">
                <button class="btn btn-primary" title="클릭하여 상태 업데이트" :class="[c.calculateStatusCode]" @click="update(c)">
                  <span>{{ getTitle(c.calculateStatusCode) }}</span>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <PaginationJpa :pageable="state.calculate.pageable" :total="state.calculate.total" :change="load" v-if="state.calculate.total" className="btn-primary"/>
    </template>
    <NoData v-else/>
  </div>
</template>

<script>import {defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import Anchor from "@/components/Anchor";
import store from "@/scripts/store";
import http from "@/scripts/http";
import NoData from "@/components/NoData";
import PaginationJpa from "@/components/PaginationJpa";

function Component(initialize) {
  this.name = "pageAdminBoardCalculateStatusList";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PaginationJpa, NoData, Anchor},

  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load(false, true);
    });

    const state = reactive({
      condition: "",
      keyword: null,
      calculate: {
        list: [],
        loaded: false,
        total: 0,
        pageable: {
          size: 0,
          page: 0,
        },
      },
    });

    const load = (page, init) => {
      if (init) {
        router.app.$route.query.status ? state.condition = router.app.$route.query.status : state.condition = "CAL_STS_0005";
        router.app.$route.query.keyword ? state.keyword = router.app.$route.query.keyword : state.keyword = null;

        setCondition("status", state.condition);
        setCondition("keyword", state.keyword);
      }

      const args = {
        page: 0,
        size: 20,
      };

      if (page) {
        args.page = page;
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }

      if (state.condition) {
        args.calculateStatusCode = state.condition;
      }
      if (state.keyword) {
        args.keyword = state.keyword.trim();
      }

      //state.condition && (args.calculateStatusCode = state.condition);
      state.calculate.loaded = false;

      for (let i = 0; i < 5; i += 1) {
        state.calculate.list.push({
          projectSeq: "0000",
          projectName: "Please wait a moment",
          accountAmt: 1000000,
          supportCharge: 0,
          subtotalCharge: 100000,
          calculateStatusCode: "wait",
        });
      }

      http.get("/api/admin/calculate/projects/status", args).then(({data}) => {
        state.calculate.loaded = true;
        state.calculate.list = data.body.content;
        state.calculate.pageable = data.body.pageable;
        state.calculate.total = data.body.total;
      });
    };

    const setCondition = (key, value) => {
      if (value === router.app.$route.query[key]) {
        return;
      }

      const query = lib.getRenewed(router.app.$route.query);

      query[key] = value;
      router.push({query});
    };

    const getTitle = (code) => {
      switch (code) {
        case null:
          return "정산 요청 예정";
        case "CAL_STS_0001":
          return "정산 요청 중";
        case "CAL_STS_0002":
          return "미정산";
        case "CAL_STS_0003":
          return "정산 마감";
        case "CAL_STS_0004":
          return "정산 확인 요청";
        case "CAL_STS_0005":
          return "정산 마감 요청";
        default:
          return "Wait";
      }
    };

    const update = (calc) => {
      let calculateStatusCode;

      switch (calc.calculateStatusCode) {
        case "CAL_STS_0004":
          calculateStatusCode = "CAL_STS_0005";
          break;
        case "CAL_STS_0005":
          calculateStatusCode = "CAL_STS_0003";
          break;
        default :
          calculateStatusCode = null;
      }

      if (!calculateStatusCode) {
        return store.commit("setSwingMessage", "해당 상태로 업데이트할 수 없습니다.");
      }

      store.commit("confirm", {
        message: "정산 상태를 업데이트 하시겠습니까?",
        subMessage: "꼭 상세 정보를 확인 하신 후 업데이트 해주세요.",
        allowTxt: "업데이트",
        allow() {
          http.put(`/api/maker/project/reward/${calc.projectSeq}/calculate/status`, {calculateStatusCode}).then(() => {
            store.commit("setSwingMessage", `${getTitle(calculateStatusCode)}이(가) 정상적으로 처리되었습니다.`);
            load();
          });
        }
      });
    };

    const getShipDate = (investEndDate, after) => {
      if (!investEndDate || !after) {
        return;
      }

      const getUntilShipDate = (d) => {
        if (!d) {
          return;
        }

        const now = new Date();
        const compareDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const endDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());

        const result = Math.ceil((compareDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24));

        if (result < 0) {
          return ` (${Math.abs(result)}일 후)`;
        } else if (result === 0) {
          return " (오늘)";
        } else {
          return "";
        }
      };

      const shipDate = new Date(new Date(lib.getDateFormat(investEndDate, "yyyy-MM-dd")).getTime());
      const endDay = shipDate.getDay();

      shipDate.setDate(shipDate.getDate() + after + (endDay === 6 ? 1 : 0) + (Math.floor((after - 1 + (endDay % 6 || 1)) / 5)) * 2);

      return lib.getDateFormat(shipDate) + getUntilShipDate(shipDate);
    };

    watch(
        () => ({
          status: router.app.$route.query.status,
          keyword: router.app.$route.query.keyword
        }),
        (next, prev) => {
          if (next.status !== prev.status || next.keyword !== prev.keyword) {
            state.calculate.total = 0;
            load();
          }
        }
    );

    const search = (remove) => {
      const query = lib.getRenewed(router.app.$route.query);

      if (remove) {
        delete query.keyword;
        state.keyword = null;
      } else {
        query.keyword = state.keyword?.trim();
      }

      query.page = 0;
      router.push({query});
    };

    return {component, state, setCondition, update, getTitle, load, getShipDate, search};
  }
});
</script>

<style lang="scss" scoped>
.calculate-status-list {
  font-size: $px14;

  > .top {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: $px20;

    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      gap: $px8;

      select {
        height: $px43;
        width: auto;
        font-size: $px12;
        margin-right: 0
      }

      .search {
        border: $px1 solid $colorBorder;
        border-radius: $px4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: border 0.18s;
        height: $px43;
        position: relative;
        z-index: 1;
        margin: 0;

        .form-control {
          height: $px41;
          font-size: $px12;
          border: 0;
        }

        .reset {
          padding: $px12;
        }

        .btn {
          font-size: $px12;
          border: 0;
          background: $colorBackground;
          height: $px41;
        }

        &:focus-within {
          border: $px1 solid $colorPurple;
        }
      }
    }
  }

  .accordion {
    padding: 0;

    .card {
      box-shadow: none;
      margin-top: 0;
      margin-bottom: 0;

      .front {
        width: 100%;
        cursor: pointer;

        > .row {
          padding: $px12;

          .title {
            display: flex;
            align-items: center;
          }
        }
      }

      .detail {
        font-size: $px13;
        background-color: $colorBackground;

        > .row {
          padding: $px12;

          .title {
            display: inline-block;
            margin-bottom: $px8;
          }

          .item {
            display: block;

            &:before {
              content: "-";
              padding-right: $px8;
            }
          }

          .btn {
            font-size: $px11;
            border: 0;
            padding: $px4 $px16;
            border-radius: $px100;

            > span {
              vertical-align: sub;
            }

            &:disabled {
              background: $colorSecondary;
            }

            &.CAL_STS_0004 {
              background: #f5365c;
            }

            &.CAL_STS_0005 {
              background: $colorPurple;
            }
          }
        }
      }

      &:first-child {
        padding: $px12;
        background-color: $colorBackground;
      }

      &:not(:first-child) .front:hover {
        background: $colorBackground;
      }
    }
  }

  .pagination {
    margin-top: $px20;
  }

  .no-data {
    padding: $px70 0;
  }

  &.skeleton {
    .top {
      span, select {
        text-overflow: initial;
        @include skeleton;
      }
    }

    .accordion {
      .card {
        .row {
          span, b, .btn {
            @include skeleton;
          }
        }
      }
    }
  }
}
</style>